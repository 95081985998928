<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Programs report</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Programs report
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader v-if="loading" type="table-thead">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 py-3 wrap-column">Title</th>
                    <th class="px-3 py-3 wrap-column">Status</th>
                    <th class="px-3 py-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr v-show="programs.length > 0" v-for="(program, index) of programs" :key="index">

                      <td class="px-3 py-2 wrap-column">
                        <span class="mb-1">
                          {{ program.title }}
                        </span>
                      </td>
                      <td class="px-3 py-2 wrap-column" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="badge badge-success text-lg`"
                          v-bind:class="{ 'badge-success': program.is_active, 'badge-danger': !program.is_active }">
                          {{ program.is_active ? 'Active' : 'Inactive' }}
                        </span>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown" no-caret right no-flip
                            text="Actions">
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <i class="flaticon-more-1"></i>
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('program', 'edit')">
                                <a href="#" class="navi-link" @click="manageProgramSummary(program.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-file-pdf"></i>
                                  </span>
                                  <span class="navi-text">Generate PDF</span>
                                </a>
                              </b-dropdown-text>


                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-show="programs.length == 0">
                      <td class="px-3 text-center" colspan="3">No data available.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">

        <v-card>
          <v-toolbar dark>
            <v-card-title class="headline">
              Manage program summary
              <hr>
            </v-card-title>

            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeDialog">
                <i class="fas fa-times"></i>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col md="12">
                  <v-checkbox v-model="program.title" readonly :label="`Program title`"></v-checkbox>
                  <v-checkbox v-model="program.affiliated_university" :label="`Affiliated university`"></v-checkbox>
                  <v-checkbox v-model="program.coordinator_name" :label="`Co-ordinator name`"></v-checkbox>
                  <v-checkbox v-model="program.duration" :label="`Duration`"></v-checkbox>
                  <v-checkbox v-model="program.total_fee" :label="`Total fee`"></v-checkbox>
                  <v-checkbox v-model="program.total_fee_by_each_semester"
                    :label="`Fee by each semester/class/level`"></v-checkbox>
                  <v-checkbox v-model="program.application_status" :label="`Application Open/closed status`"></v-checkbox>
                  <v-checkbox v-model="program.courses_in_each_semester" :label="`Courses in each semester`"></v-checkbox>
                  <v-checkbox v-model="program.admission_process" :label="`Admission process`"></v-checkbox>
                </v-col>

              </v-row>
            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn btn-standard cancel-btn" depressed @click="closeDialog">
              Close
            </v-btn>
            <v-btn class="text-white ml-2 btn btn-primary" depressed @click="generateProgramPDF()">
              Generate
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
const program = new ProgramService;
export default {
  data() {
    return {
      loading: false,
      programs: [],
      active_statuses: [
        { title: 'Active', value: "active" },
        { title: 'Inactive', value: "in_active" }
      ],
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active"
      },
      dialog: false,
      program: {
        id: '',
        title: true,
        affiliated_university: true,
        coordinator_name: true,
        duration: true,
        total_fee: true,
        total_fee_by_each_semester: true,
        application_status: true,
        courses_in_each_semester: true,
        admission_process: true,
      }
    }

  },
  methods: {
    getPrograms() {
      this.loading = true;
      program
        .paginate(this.search).then(response => {
          this.programs = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
          // console.log(err)
        }).finally(() => {
          this.loading = false;
        });
    },
    generateProgramPDF() {
      this.dialog = false;
      program
        .generateProgramPDF(this.program.id, this.program)
        .then(response => {
          this.closeDialog()
        })
        .catch((err) => {

        }).finally(() => {

        });
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.program = {
        id: '',
        title: true,
        affiliated_university: true,
        coordinator_name: true,
        duration: true,
        total_fee: true,
        total_fee_by_each_semester: true,
        application_status: true,
        courses_in_each_semester: true,
        admission_process: true,

      };
    },
    manageProgramSummary(programId) {
      this.program.id = programId;
      this.dialog = true;
    },
  },
  mounted() {
    this.getPrograms();
  }
}
</script>